@charset "UTF-8";
/* CSS Document */

* {/* margin: 0; padding: 0;*/ box-sizing: border-box;}
ul {list-style: none;}
html,body {min-height: 100vh; margin: 0; padding: 0;}
/*section {width: 100%;}*/
img {max-width: 100%; height: auto;}
a,a:hover {text-decoration: none;}
ul {list-style: none; margin: 0;}
a, li, button {-webkit-transition: all .2s;transition: all .2s;}
a, button {cursor: pointer;}
button {border: 0;}

/* Display flex */
html {-webkit-font-smoothing: antialiased;}
.clearfix:before,.clearfix:after {content: " "; display: table;}
.clearfix:after {clear: both;}
.single-line {text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
.flex {display: flex;}
.inline-flexbox {-webkit-display:inline-flex; -ms-display:inline-flex; display:inline-flex;}
.flex-column {flex-direction: column;}

/* -- Display grid -- */
.grid-self-end {align-self: end;}
.grid {display: grid; grid-gap: 20px; grid-auto-columns: -webkit-max-content; grid-auto-columns: max-content; grid-auto-flow: column; align-items: center;}
.autogrid {display: grid; grid-gap: 20px; grid-auto-columns: auto; grid-auto-flow: column; align-items: center;}
.g9 {grid-template-columns: repeat(9, 1fr);}
.g12 {grid-template-columns: repeat(12, 1fr);}
.g2 {grid-template-columns: 1fr 1fr;}

/* Flex options */
.wrap {flex-wrap: wrap;}
.wrap-full {flex-wrap: wrap;}
.wrap-full > * {width: 100%}
.between { -ms-justify-content: space-between;justify-content: space-between;}
.around { -ms-justify-content: space-around;justify-content: space-around;}
.evenly { -ms-justify-content: space-evenly;justify-content: space-evenly;}
.align-end { -ms-align-content: flex-end;align-content: flex-end;}
.center {-webkit-justify-content: center; -webkit-align-items: center;-ms-justify-content: center; -ms-align-items: center;justify-content: center; align-items: center;}
.stretch {-ms-align-content: stretch;align-content: stretch;}
.flex-stretch {-ms-align-items: stretch;align-items: stretch;}
.start {-ms-align-content: flex-start;align-content: flex-start;}
.content-center {-ms-align-content: center;align-content: center;}
.grow {-ms-flex-grow: 1;flex-grow: 1;}
.growhide {overflow: hidden;-ms-flex-grow: 1;flex-grow: 1;}
.no-grow {-ms-flex-grow: 0;flex-grow: 0;}
.self-stretch { -ms-align-self: stretch;align-self: stretch;}
.self-end {-ms-align-self: flex-end; align-self: flex-end;}
.flex-start {-ms-justify-content: flex-start;justify-content: flex-start;}
.flex-end {-ms-justify-content: flex-end;justify-content: flex-end;}
.flex-bot {-ms-align-items: flex-end; align-items: flex-end;}
.flex-top {-ms-align-items: flex-start; align-items: flex-start;}
.no-shrink,.noshrink-childs > * { -ms-flex-shrink: 0; flex-shrink: 0;}
.flex > div.no-shrink {-ms-flex-shrink: 0; flex-shrink: 0;}
.flex.noshrink-childs > div {-ms-flex-shrink: 0; flex-shrink: 0;}
.grow-childs > * {-ms-flex-grow: 1;flex-grow: 1;}
.v-center {-ms-align-items: center;align-items: center;}
.h-center {-ms-justify-content: center; justify-content: center;}
.flex-full > * {width: 100%;}
.inline-block {display: inline-block;}
.inline-childs > * {vertical-align: middle; display: inline-block;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}
.text-uppercase {text-transform: uppercase;}
.hidden {display: none;}
.invisible {visibility: hidden; opacity: 0;}
.visible {visibility: visible; opacity: 1;}
.text-normal {text-transform: none;}
.normal {font-weight: normal;}
.absolute {position: absolute; width: 100%;}
.img > img{display: block;}
.mgbot {margin-bottom: 10px;}
.mgright {margin-right: 10px;}
.float-left {float: left;}
.material-icons {font-size: 1em;}
.fixed-table {table-layout: fixed;}
.big {font-size: 1.2em;}

.prev_a {
  color: #62C5E8; }

button:not(.prev_a) {
  all: unset;
  box-sizing: border-box;
  cursor: pointer; }

.btn {
  color: white;
  background: -webkit-gradient(linear, right top, left top, from(#A6A6FF), to(#49B6E8));
  background: linear-gradient(-90deg, #A6A6FF 0%, #49B6E8 100%);
  font-size: 14px;
  padding: 10px;
  min-width: 130px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: all .2s;
  transition: all .2s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.15); }

.btn:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#96D9FF), to(#49B6E8));
  background: linear-gradient(90deg, #96D9FF 0%, #49B6E8 100%);
  z-index: -1;
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s; }

.btn.min {
  min-width: 0;
  font-size: 13px;
  text-transform: none; }

.btn:hover:before {
  opacity: 1; }

.btn:disabled, .btn:disabled:hover {
  background: #C8C8C8;
  box-shadow: none;
  color: #EDEDED;
  cursor: default; }

.btn:disabled:before {
  display: none; }

/* Home */
header {
  background-color: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03);
  display: grid;
  grid-template-columns: repeat(9, 1fr) repeat(3, 100px);
  padding: 15px;
  grid-gap: 15px; }
  header .prev_a {
    font-size: 32px;
    line-height: 0;
    padding: 0px;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center; }
  header .prev_a:not(.btn-faces):not(.btn-vertex) .old-svg polygon, header .prev_a:not(.btn-faces):not(.btn-vertex) .old-svg path {
    fill: #62C5E8; }
  header .prev_a:not(.btn-faces):not(.btn-vertex):hover .old-svg polygon, header .prev_a:not(.btn-faces):not(.btn-vertex):hover .old-svg path {
    fill: #0F9DDD; }
  header .btn-vertex, header .btn-faces {
    opacity: .8; }
  header .prev_a:not(.btn-faces):not(.btn-vertex).active .old-svg polygon, header .prev_a:not(.btn-faces):not(.btn-vertex).active .old-svg path, header .prev_a:not(.btn-faces):not(.btn-vertex).active:hover .old-svg polygon, header .prev_a:not(.btn-faces):not(.btn-vertex).active:hover path {
    fill: #ff7091; }
  header .prev_a.active, header .prev_a:hover.active {
    color: #ff7091;
    background-color: #ffedf1;
    opacity: 1; }
    header .prev_a.active polygon, header .prev_a.active path, header .prev_a:hover.active polygon, header .prev_a:hover.active path {
      fill: #ff7091; }
  header .btn-faces.active #Fill-1, header .btn-faces:hover.active #Fill-1 {
    fill: #ff8ca7; }
  header .btn-faces.active #Fill-2, header .btn-faces:hover.active #Fill-2 {
    fill: #FFA0B6; }
  header .btn-faces.active #Fill-3, header .btn-faces:hover.active #Fill-3 {
    fill: #ff7091; }
  header .btn-vertex.active #XMLID_4_, header .btn-vertex:hover.active #XMLID_4_ {
    fill: url(#linearGradient-1hover); }
  header .btn-vertex.active #XMLID_3_, header .btn-vertex:hover.active #XMLID_3_ {
    fill: url(#linearGradient-2hover); }
  header .btn-vertex.active #XMLID_2_, header .btn-vertex:hover.active #XMLID_2_ {
    fill: url(#linearGradient-3hover); }
  header .prev_a:hover {
    color: #0F9DDD;
    background-color: #E4F7FF;
    fill: #0F9DDD;
    opacity: 1; }
  header .prev_a.disabled, header .btn-vertex.disabled, header .btn-faces.disabled {
    cursor: default;
    color: black;
    background-color: transparent; }
    header .prev_a.disabled > *:not(.submenu), header .btn-vertex.disabled > *:not(.submenu), header .btn-faces.disabled > *:not(.submenu) {
      opacity: .2; }
    header .prev_a.disabled path, header .prev_a.disabled polygon, header .btn-vertex.disabled path, header .btn-vertex.disabled polygon, header .btn-faces.disabled path, header .btn-faces.disabled polygon {
      fill: black !important; }
  header > .autogrid {
    grid-column: 1/10;
    grid-gap: 0; }
    header > .autogrid .autogrid:not(:last-child) {
      border-right: 2px solid #e2e2e2; }
    header > .autogrid .autogrid {
      justify-content: space-evenly;
      padding: 0 15px; }
  header .logo {
    grid-column: 10/13;
    text-transform: uppercase; }
    header .logo span {
      color: #e2e2e2; }
    header .logo a, .prev_a {
      all: unset;
      cursor: pointer;
      color: #62C5E8;
      font-weight: 600; }
    header .logo a:hover, .prev_a:hover {
      color: #0F9DDD; }
  header .autogrid {
    position: relative; }
  header .open-menu:hover .submenu {
    display: block;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .3s;
    transition: all .3s;
    pointer-events: auto; }
  header .submenu {
    position: absolute;
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    z-index: 1;
    width: auto;
    left: 50%;
    top: 100%;
    -webkit-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%);
    opacity: 0;
    visibility: hidden;
    background: #FEFEFE;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 10px;
    min-width: 225px; }
    header .submenu li:not(:last-child) {
      margin-bottom: 10px; }
    header .submenu .label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: normal;
      width: auto;
      height: auto;
      padding: 5px 10px;
      padding-right: 30px;
      background: #F5F5F5;
      border-radius: 10px;
      font-size: 13px;
      text-transform: uppercase;
      color: #666666;
      font-weight: 600;
      -webkit-transition: all .3s;
      transition: all .3s; }
      header .submenu .label .prev_img {
        margin-right: 13px; }
    header .submenu .label:after {
      content: 'check';
      font-family: 'Material Icons';
      text-transform: none;
      position: absolute;
      right: 10px;
      font-size: 20px;
      color: #ff7091;
      display: none; }
    header .submenu .label:hover {
      background-color: #E4F7FF;
      color: #0F9DDD; }
    header .submenu .checkmenu input[type="checkbox"] {
      display: none; }
    header .submenu .checkmenu input[type="checkbox"]:checked ~ .label, header .submenu .checkmenu input[type="checkbox"]:checked ~ .label:hover {
      background-color: #ffedf1;
      color: #666666; }
    header .submenu .checkmenu input[type="checkbox"]:checked ~ .label:after {
      display: block; }

h1 {
  margin: 0; }

h4 {
  font-size: 13px;
  text-transform: uppercase;
  margin: 20px 0; }

ul {
  padding: 0; }

.grid, .autogrid {
  grid-gap: 15px; }

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #F5F5F5;
  color: #666666;
  min-width: 1170px;
  font-size: 14px; }

#main-view {
  padding: 15px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(9, 1fr) repeat(3, 100px);
  grid-template-rows: 520px; }
  #main-view .model-view {
    border-radius: 10px;
    background-color: #EDEDED;
    min-height: 490px;
    position: relative; }
    #main-view .model-view canvas {
      width: 100%;
      height: 100%; }
  #main-view .selections {
    grid-column: 10/13; }
  #main-view .results {
    grid-column: 1/10; }
    #main-view .results .info {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: -webkit-min-content;
      grid-auto-columns: min-content;
      grid-gap: 20px; }
  #main-view .evaluate {
    grid-column: 10/13; }

#main-view.view0 .model-view {
  grid-column: 1/10;
  grid-row: 1/end; }

#main-view.view0 .selections {
  grid-column: 10/13; }

#main-view.view0 .results {
  display: none; }

#main-view.view0 .evaluate {
  grid-column: 10/13; }

#main-view.view1 .model-view {
  grid-column: 1/10; }

#main-view.view1 .results {
  grid-column: 1/10; }

#main-view.view2 .model-view {
  grid-column: 1/6;
  grid-row: 1/end; }

#main-view.view2 .results {
  grid-column: 6/10;
  grid-row: 1/end; }
  #main-view.view2 .results .info {
    grid-auto-flow: row; }

#main-view.view3 .results {
  grid-column: 1/10;
  grid-row: 1/end; }
  #main-view.view3 .results .info {
    grid-auto-flow: row; }

#main-view.view3 .model-view {
  grid-column: 7/10;
  grid-row: 1/2;
  align-self: flex-start;
  min-height: 290px; }
  #main-view.view3 .model-view .model-statistics .autogrid {
    grid-auto-flow: row;
    grid-gap: 5px; }

.card {
  background-color: white;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  padding: 15px 30px; }

.tooltiped {
  position: relative; }

.tooltiped:before {
  opacity: 0;
  visibility: hidden;
  opacity: 0;
  top: -5px;
  left: 50%;
  background-color: #EDEDED;
  color: #666666;
  font-size: 12px;
  padding: 8px 10px;
  position: absolute;
  line-height: 1;
  font-family: 'Source Sans Pro', sans-serif;
  content: attr(data-tooltip);
  border-radius: 4px;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.35);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
  white-space: nowrap;
  z-index: 9; }

.tooltiped:hover:before {
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: .1s;
          transition-delay: .1s; }

header .tooltiped:before {
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%); }

header .tooltiped:hover:before {
  -webkit-transform: translate(-50%, 150%);
          transform: translate(-50%, 150%); }

.tabs {
  display: flex;
  border-bottom: 1px solid #FFA0B6;
  position: relative; }
  .tabs .tab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: #C8C8C8; }
  .tabs .tab.active {
    color: #666666; }
  .tabs .tab:hover {
    background-color: #F5F5F5;
    color: #666666; }
  .tabs h4 {
    margin: 0; }

.tabs-body {
  padding: 20px 0; }

.tabs:before {
  content: '';
  width: 50%;
  height: 3px;
  background-color: #FFA0B6;
  position: absolute;
  bottom: 0;
  -webkit-transition: left .3s;
  transition: left .3s; }

.tab1:before {
  left: 0; }

.tab2:before {
  left: 50%; }

.tab-content:not(.active) {
  display: none; }

.drop-down {
  position: relative;
  display: flex;
  font-size: 16px; }
  .drop-down select {
    display: none; }
  .drop-down .button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E4F7FF;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    -webkit-transition: .5s all;
    transition: .5s all; }
    .drop-down .button .prev_img {
      margin-right: 10px; }
    .drop-down .button > div {
      display: flex;
      align-items: center; }
    .drop-down .button a {
      line-height: 0;
      font-size: 24px; }
    .drop-down .button .prev_a {
      line-height: 0;
      font-size: 24px; }
  .drop-down .button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 1px 0px rgba(0, 0, 0, 0.05); }
  .drop-down .select-list {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 0px;
    background: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(122, 144, 153, 0.47);
    border-radius: 5px;
    color: #666666; }
    .drop-down .select-list li {
      padding: 10px;
      cursor: pointer; }
    .drop-down .select-list li:hover {
      background-color: #E4F7FF; }
  .drop-down .select-list.active {
    display: block; }
  .drop-down .select-list li {
    display: flex;
    align-items: center; }
    .drop-down .select-list li .prev_img {
      margin-right: 10px; }
  .drop-down .select-list li span {
    width: 100%;
    background-position: left 10px center;
    background-repeat: no-repeat;
    text-align: left;
    box-sizing: border-box; }
  .drop-down .select-list li span:hover, .drop-down .select-list li span:focus {
    opacity: 1; }

.radio {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .radio input[type="radio"] {
    display: none; }
  .radio .input-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #C8C8C8;
    margin-right: 10px;
    position: relative; }
  .radio .input-radio:before {
    content: '';
    width: 0%;
    height: 0%;
    background-color: #0F9DDD;
    display: block;
    border-radius: 50%;
    -webkit-transition: .1s all;
    transition: .1s all; }
  .radio input[type="radio"]:checked ~ .input-radio:before {
    width: 60%;
    height: 60%; }
  .radio span {
    margin-right: 10px; }

input {
  border: 0;
  background-color: #EDEDED;
  padding: 6px;
  border-radius: 4px;
  min-height: 25px;
  width: 100%;
  font-size: 14px;
  color: #666666;
  opacity: .5; }

input:not(:disabled), textarea:not(:disabled) {
  background-color: #E4F7FF;
  opacity: 1; }

input::-webkit-input-placeholder {
  color: #C8C8C8; }

input::-ms-input-placeholder {
  color: #C8C8C8; }

input::placeholder {
  color: #C8C8C8; }

input::-ms-input-placeholder {
  color: red; }

/* Microsoft Edge */
textarea {
  margin: 10px 0 0;
  width: 100%;
  resize: none;
  padding: 10px;
  height: 60px;
  background-color: #EDEDED;
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.3;
  opacity: .5; }

.select-box {
  padding: 10px;
  margin-bottom: 10px;
  min-height: 175px;
}

.select-box > div:not(:last-child) {
  margin-bottom: 15px; }

.angle-box input {
  max-width: 75px; }

.angle-box .radio {
  width: 80px; }

.id-box input {
  max-width: 75px; }

.id-box .radio {
  margin-right: 10px; }

.selectionmode-box {
  margin-bottom: 20px; }
  .selectionmode-box h4 {
    margin-bottom: 10px; }
  .selectionmode-box > div {
    border-radius: 5px;
    padding: 10px 0;
    width: calc(100% - 20px);
    margin: 0 auto;
    background-color: #F5F5F5; }
  .selectionmode-box .radio .input-radio {
    width: 50px;
    height: 50px;
    margin: 0;
    border-radius: 5px;
    border: 0;
    background-color: transparent;
    -webkit-transition: background-color .3s;
    transition: background-color .3s; }
  .selectionmode-box .radio .input-radio:hover {
    background-color: #e2e2e2; }
    .selectionmode-box .radio .input-radio:hover path, .selectionmode-box .radio .input-radio:hover rect {
      stroke: #8B8B8B !important;
      -webkit-transition: stroke .3s;
      transition: stroke .3s; }
  .selectionmode-box .radio .input-radio:before {
    display: none; }
  .selectionmode-box .radio input[type="radio"]:checked ~ .input-radio {
    background-color: #ffedf1; }
    .selectionmode-box .radio input[type="radio"]:checked ~ .input-radio path, .selectionmode-box .radio input[type="radio"]:checked ~ .input-radio rect {
      stroke: #FFA0B6 !important;
      -webkit-transition: stroke .3s;
      transition: stroke .3s; }

#tab-history ul li {
  background-color: #F5F5F5;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px; }
  #tab-history ul li .prev_img {
    width: 25px;
    margin-right: 6px; }
  #tab-history ul li i {
    cursor: pointer;
    font-size: 1.5em;
    color: #FFA0B6;
    -webkit-transition: color .2s;
    transition: color .2s; }
  #tab-history ul li i:hover {
    color: #ff7091; }

#tab-history ul li:not(:last-child) {
  margin-bottom: 10px; }

.model-opt {
  position: absolute;
  top: 20px;
  right: 20px; }
  .model-opt .view-opt {
    background: #F5F5F5;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    overflow: hidden;
    padding: 15px 18px;
    display: block;
    margin-bottom: 6px; }
  .model-opt .view-opt:hover {
    background-color: white;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1); }
  .model-opt .zoom {
    background: #F5F5F5;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    overflow: hidden;
    padding: 4px;
    display: flex;
    align-items: center;
    width: 60px; }
    .model-opt .zoom i {
      font-size: 16px;
      color: #AFAFAF; }
    .model-opt .zoom input {
      flex-grow: 1;
      font-size: 11px;
      font-weight: 500;
      background-color: transparent;
      padding: 4px;
      min-height: 0; }
    .model-opt .zoom input:focus {
      background-color: white;
      outline: none;
      color: #0F9DDD; }
  .model-opt .scene {
    width: 24px;
    height: 24px;
    -webkit-perspective: 600px;
            perspective: 600px;
    margin: 0 auto;
    -webkit-transition: -webkit-perspective 1s linear, -webkit-transform .2s ease;
    transition: -webkit-perspective 1s linear, -webkit-transform .2s ease;
    transition: perspective 1s linear, transform .2s ease;
    transition: perspective 1s linear, transform .2s ease, -webkit-perspective 1s linear, -webkit-transform .2s ease; }
  .model-opt .scene.perspective {
    -webkit-perspective: 50px;
            perspective: 50px;
    -webkit-transition: -webkit-perspective 0.2s cubic-bezier(0.22, 0.61, 0.36, 1), -webkit-transform 0.3s ease;
    transition: -webkit-perspective 0.2s cubic-bezier(0.22, 0.61, 0.36, 1), -webkit-transform 0.3s ease;
    transition: perspective 0.2s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.3s ease;
    transition: perspective 0.2s cubic-bezier(0.22, 0.61, 0.36, 1), transform 0.3s ease, -webkit-perspective 0.2s cubic-bezier(0.22, 0.61, 0.36, 1), -webkit-transform 0.3s ease;
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  .model-opt .cube {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: rotateY(45deg) rotateZ(-22deg) rotateX(-20deg);
            transform: rotateY(45deg) rotateZ(-22deg) rotateX(-20deg); }
  .model-opt .face {
    width: 22px;
    height: 22px;
    position: absolute; }
  .model-opt .face.top {
    background-color: #8C9BF4;
    -webkit-transform: rotateX(-90deg) translateZ(-12px);
            transform: rotateX(-90deg) translateZ(-12px); }
  .model-opt .face.left {
    background-color: #FFA0B6;
    -webkit-transform: rotateY(-90deg) translateZ(12px);
            transform: rotateY(-90deg) translateZ(12px); }
  .model-opt .face.right {
    background-color: #62C5E8;
    -webkit-transform: rotateY(180deg) translateZ(-12px);
            transform: rotateY(180deg) translateZ(-12px); }

.model-statistics {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  padding: 3px 5px;
  font-weight: 500;
  text-align: center;
  max-width: calc(100% - 20px); }
  .model-statistics .coord {
    color: #ff7091;
    grid-auto-rows: auto;
    grid-auto-flow: column;
    grid-gap: 10px;
    display: grid; }
    .model-statistics .coord :first-child {
      color: #0F9DDD; }
    .model-statistics .coord :last-child {
      color: #8C9BF4; }
  .model-statistics .stadistics {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: flex-start;
    grid-gap: 5px;
    color: #8B8B8B; }

.modal {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.6); }

.modal.hide {
  opacity:0;
  pointer-events: none;}

.modal .close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #FFA0B6;
  -webkit-transition: color .3s;
  transition: color .3s; }

.modal .close:hover {
  color: #ff7091; }

.modal .modal-body {
  padding: 15px;
  padding-top: 0; }

.modal .modal-header {
  padding: 10px 15px;
  padding-right: 40px; }

.modal .modal-header h3 {
  text-transform: uppercase;
  margin: 15px 0;
  font-size: 20px; }

.modal .modal-header h4 {
  margin: 10px 0; }

.modal p {
  font-size: 16px;
  margin: 0 0 10px;
  line-height: 1.5; }

.modal.loader .modal-body {
  padding: 10px 50px 30px; }

.modal.loader h5 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  letter-spacing: 4px;
  font-size: 14px;
  color: #8B8B8B;
  margin: 20px; }

.modal > div {
  width: 100%;
  height: 100%;
  padding: 3%; }

.modal-container {
  width: auto;
  max-width: 550px;
  position: relative;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15), 0 24px 24px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px; }

.ui-list {
  min-width: 200px; }
  .ui-list li {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; }
  .ui-list li:hover {
    background-color: #E4F7FF; }
  .ui-list li.active {
    background-color: #ffedf1;
    border-left: 2px solid #ff7091;
    cursor: default; }
  .ui-list .color-indicator {
    width: 24px;
    height: 24px;
    border: 1px solid #AFAFAF;
    background: white; }

.picker {
  min-width: 100px;
  min-height: 100px;
  background: #F5F5F5;
  background-clip: content-box; }

.modal.warning, .modal.error {
  text-align: center; }
  .modal.warning .modal-container, .modal.error .modal-container {
    min-width: 250px;
    padding: 10px; }
  .modal.warning .modal-header, .modal.error .modal-header {
    padding: 10px;
    text-align: center; }
    .modal.warning .modal-header .icon:before, .modal.error .modal-header .icon:before {
      font-family: 'Material icons';
      font-size: 36px; }
    .modal.warning .modal-header h4, .modal.error .modal-header h4 {
      font-size: 20px;
      font-weight: 400;
      margin: 0; }
  .modal.warning .modal-body, .modal.error .modal-body {
    padding-top: 0; }

.modal.warning .modal-header {
  color: #F6CA90; }
  .modal.warning .modal-header .icon:before {
    content: 'warning'; }

.modal.error .modal-header {
  color: #ff7091; }
  .modal.error .modal-header .icon:before {
    content: 'error'; }

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto; }

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #62C5E8;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out; }

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  background-color: #FFA0B6; }

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/*# sourceMappingURL=style.css.map */
@charset "UTF-8";

/* ANIMACIONES */

.open-anim {-webkit-animation: from-top .3s cubic-bezier(0.22, 0.61, 0.36, 1);animation: from-top .3s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.close-anim {-webkit-animation: close .2s cubic-bezier(0.22, 0.61, 0.36, 1);animation: close .2s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.single {-webkit-animation: single-in .8s cubic-bezier(0.22, 0.61, 0.36, 1);animation: single-in .8s cubic-bezier(0.22, 0.61, 0.36, 1);}
.single.single-out,.single-out {-webkit-animation: single-out .7s cubic-bezier(0.22, 0.61, 0.36, 1);animation: single-out .7s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.page-in-left {-webkit-animation: page-in-left .5s cubic-bezier(0.22, 0.61, 0.36, 1);animation: page-in-left .5s cubic-bezier(0.22, 0.61, 0.36, 1);}
.page-out-left {-webkit-animation: page-out-left .4s cubic-bezier(0.22, 0.61, 0.36, 1);animation: page-out-left .4s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.page-in-right {-webkit-animation: page-in-right .5s cubic-bezier(0.22, 0.61, 0.36, 1);animation: page-in-right .5s cubic-bezier(0.22, 0.61, 0.36, 1);}
.page-out-right {-webkit-animation: page-out-right .4s cubic-bezier(0.22, 0.61, 0.36, 1);animation: page-out-right .4s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.fade-in {opacity: 0; -webkit-animation: open 1s cubic-bezier(0.45, 0.05, 0.55, 0.95); animation: open 1s cubic-bezier(0.45, 0.05, 0.55, 0.95); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.dark-bg {-webkit-animation: dark-bg 1s ease-out;animation: dark-bg 1s ease-out; -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.seq-in {-webkit-animation: open 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);animation: open 1s cubic-bezier(0.45, 0.05, 0.55, 0.95); -webkit-animation-fill-mode: backwards; animation-fill-mode: backwards; visibility: visible;}
.seq-out {-webkit-animation: close 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);animation: close 1s cubic-bezier(0.45, 0.05, 0.55, 0.95); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards; visibility: hidden;}
.from-top {-webkit-animation: from-top 1s cubic-bezier(0.45, 0.05, 0.55, 0.95);animation: from-top 1s cubic-bezier(0.45, 0.05, 0.55, 0.95); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.tab-in {-webkit-animation: open .5s cubic-bezier(0.45, 0.05, 0.55, 0.95);animation: open .5s cubic-bezier(0.45, 0.05, 0.55, 0.95); -webkit-animation-fill-mode: backwards; animation-fill-mode: backwards; visibility: visible;}
.rotate {-webkit-animation: rotate 1s linear infinite;animation: rotate 1s linear infinite;}
.rotate-mask {-webkit-animation: rotate-mask 3s ease-in-out infinite;animation: rotate-mask 3s ease-in-out infinite; overflow: hidden;}
.bottom-in {-webkit-animation: bottom-in .5s cubic-bezier(0.22, 0.61, 0.36, 1);animation: bottom-in .5s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.bottom-out {-webkit-animation: bottom-out .5s cubic-bezier(0.22, 0.61, 0.36, 1);animation: bottom-out .5s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.top-in {-webkit-animation: top-in .5s cubic-bezier(0.22, 0.61, 0.36, 1);animation: top-in .5s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}
.top-out {-webkit-animation: top-out .5s cubic-bezier(0.22, 0.61, 0.36, 1);animation: top-out .5s cubic-bezier(0.22, 0.61, 0.36, 1); -webkit-animation-fill-mode: forwards; animation-fill-mode: forwards;}

@-webkit-keyframes single-in {
	from {-webkit-transform: translateY(100%);transform: translateY(100%);}
	to {-webkit-transform: translateY(0%);transform: translateY(0%);}
}
@keyframes single-in {
	from {-webkit-transform: translateY(100%);transform: translateY(100%);}
	to {-webkit-transform: translateY(0%);transform: translateY(0%);}
}
@-webkit-keyframes single-out {
	from {-webkit-transform: translateY(0%);transform: translateY(0%);}
	to {-webkit-transform: translateY(100%);transform: translateY(100%);}
}
@keyframes single-out {
	from {-webkit-transform: translateY(0%);transform: translateY(0%);}
	to {-webkit-transform: translateY(100%);transform: translateY(100%);}
}
@-webkit-keyframes page-in-left {
	from {-webkit-transform: translateX(100%);}
	to {-webkit-transform: translateX(0%);}
}
@keyframes page-in-left {
	from {-webkit-transform: translateX(100%);transform: translateX(100%);}
	to {-webkit-transform: translateX(0%);transform: translateX(0%);}
}
@-webkit-keyframes page-out-left {
	from {-webkit-transform: translateX(0%);}
	to {-webkit-transform: translateX(100%);}
}
@keyframes page-out-left {
	from {-webkit-transform: translateX(0%);transform: translateX(0%);}
	to {-webkit-transform: translateX(100%);transform: translateX(100%);}
}
@-webkit-keyframes page-in-right {
	from {-webkit-transform: translateX(-100%);}
	to {-webkit-transform: translateX(0%);}
}
@keyframes page-in-right {
	from {-webkit-transform: translateX(-100%);transform: translateX(-100%);}
	to {-webkit-transform: translateX(0%);transform: translateX(0%);}
}
@-webkit-keyframes page-out-right {
	from {-webkit-transform: translateX(0%);}
	to {-webkit-transform: translateX(-100%);}
}
@keyframes page-out-right {
	from {-webkit-transform: translateX(0%);transform: translateX(0%);}
	to {-webkit-transform: translateX(-100%);transform: translateX(-100%);}
}
@-webkit-keyframes open {
	from {opacity: 0;}
	to {opacity: 1;}
}
@keyframes open {
	from {opacity: 0;}
	to {opacity: 1;}
}
@-webkit-keyframes close {
	0 {opacity: 1;}
    99% {visibility: visible;}
	100% {opacity: 0; visibility: hidden;}
}
@keyframes close {
	0 {opacity: 1;}
    99% {visibility: visible;}
	100% {opacity: 0; visibility: hidden;}
}
@-webkit-keyframes from-top {
	from {opacity: 0; -webkit-transform: translateY(-10%); transform: translateY(-10%);}
	to {opacity: 1; -webkit-transform:  translateY(0%); transform:  translateY(0%);}
}
@keyframes from-top {
	from {opacity: 0; -webkit-transform: translateY(-10%); transform: translateY(-10%);}
	to {opacity: 1; -webkit-transform:  translateY(0%); transform:  translateY(0%);}
}
@-webkit-keyframes dark-bg {
	from {opacity: 1;}
	to {opacity: 0.3;}
}
@keyframes dark-bg {
	from {opacity: 1;}
	to {opacity: 0.3;}
}
@-webkit-keyframes bottom-in {
	from {-webkit-transform: translateY(100%);}
	to {-webkit-transform: translateY(0%);}
}
@keyframes bottom-in {
	from {-webkit-transform: translateY(100%);transform: translateY(100%);}
	to {-webkit-transform: translateY(0%);transform: translateY(0%);}
}
@-webkit-keyframes bottom-out {
	from {-webkit-transform: translateY(0%);}
	to {-webkit-transform: translateY(100%);}
}
@keyframes bottom-out {
	from {-webkit-transform: translateY(0%);transform: translateY(0%);}
	to {-webkit-transform: translateY(100%);transform: translateY(100%);}
}
@-webkit-keyframes top-in {
	from {-webkit-transform: translateY(-100%);transform: translateY(-100%);}
	to {-webkit-transform: translateY(0%);transform: translateY(0%);}
}
@keyframes top-in {
	from {-webkit-transform: translateY(-100%);transform: translateY(-100%);}
	to {-webkit-transform: translateY(0%);transform: translateY(0%);}
}
@-webkit-keyframes top-out {
	from {-webkit-transform: translateY(0%);transform: translateY(0%);}
	to {-webkit-transform: translateY(-10%);transform: translateY(-10%);}
}
@keyframes top-out {
	from {-webkit-transform: translateY(0%);transform: translateY(0%);}
	to {-webkit-transform: translateY(-10%);transform: translateY(-10%);}
}
@-webkit-keyframes check {
	0 {-webkit-transform:scale(1);}
	80% {-webkit-transform: scale(1.2);}
	100% {-webkit-transform: scale(1);}
}
@keyframes check {
	0 {-webkit-transform:scale(1);transform:scale(1);}
	80% {-webkit-transform: scale(1.2);transform: scale(1.2);}
    100% {-webkit-transform: scale(1);transform: scale(1);}
}

/* Loader */

@-webkit-keyframes rotate {
    0%    { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
    100%  { -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}

@keyframes rotate {
    0%    { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
    100%  { -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}
@-webkit-keyframes rotate-mask {
    0%    {-webkit-clip-path: circle(0% at bottom right);clip-path: circle(0% at bottom right);}
    50%   {-webkit-clip-path: circle(100% at bottom right);clip-path: circle(100% at bottom right);}
    100%  {-webkit-clip-path: circle(0% at bottom right);clip-path: circle(0% at bottom right);}
}
@keyframes rotate-mask {
    0%    {-webkit-clip-path: circle(0% at bottom right);clip-path: circle(0% at bottom right);}
    50%   {-webkit-clip-path: circle(100% at bottom right);clip-path: circle(100% at bottom right);}
    100%  {-webkit-clip-path: circle(0% at bottom right);clip-path: circle(0% at bottom right);}
}
