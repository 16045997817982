@charset "UTF-8";
/* CSS Document */

* {/* margin: 0; padding: 0;*/ box-sizing: border-box;}
ul {list-style: none;}
html,body {min-height: 100vh; margin: 0; padding: 0;}
/*section {width: 100%;}*/
img {max-width: 100%; height: auto;}
a,a:hover {text-decoration: none;}
ul {list-style: none; margin: 0;}
a, li, button {transition: all .2s;}
a, button {cursor: pointer;}
button {border: 0;}

/* Display flex */
html {-webkit-font-smoothing: antialiased;}
.clearfix:before,.clearfix:after {content: " "; display: table;}
.clearfix:after {clear: both;}
.single-line {text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
.flex {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}
.inline-flexbox {-webkit-display:inline-flex; -ms-display:inline-flex; display:inline-flex;}
.flex-column {-webkit-flex-direction: column;-ms-flex-direction: column;flex-direction: column;}

/* -- Display grid -- */
.grid-self-end {align-self: end;}
.grid {display: grid; grid-gap: 20px; grid-auto-columns: max-content; grid-auto-flow: column; align-items: center;}
.autogrid {display: grid; grid-gap: 20px; grid-auto-columns: auto; grid-auto-flow: column; align-items: center;}
.g9 {grid-template-columns: repeat(9, 1fr);}
.g12 {grid-template-columns: repeat(12, 1fr);}
.g2 {grid-template-columns: 1fr 1fr;}

/* Flex options */
.wrap {-webkit-flex-wrap: wrap; -ms-flex-wrap: wrap;flex-wrap: wrap;}
.wrap-full {-webkit-flex-wrap: wrap; -ms-flex-wrap: wrap;flex-wrap: wrap;}
.wrap-full > * {width: 100%}
.between {-webkit-justify-content: space-between; -ms-justify-content: space-between;justify-content: space-between;}
.around {-webkit-justify-content: space-around; -ms-justify-content: space-around;justify-content: space-around;}
.evenly {-webkit-justify-content: space-evenly; -ms-justify-content: space-evenly;justify-content: space-evenly;}
.align-end {-webkit-align-content: flex-end; -ms-align-content: flex-end;align-content: flex-end;}
.center {-webkit-justify-content: center; -webkit-align-items: center;-ms-justify-content: center; -ms-align-items: center;justify-content: center; align-items: center;}
.stretch {-webkit-align-content: stretch;-ms-align-content: stretch;align-content: stretch;}
.flex-stretch {-webkit-align-items: stretch;-ms-align-items: stretch;align-items: stretch;}
.start {-webkit-align-content: flex-start;-ms-align-content: flex-start;align-content: flex-start;}
.content-center {-webkit-align-content: center;-ms-align-content: center;align-content: center;}
.grow {-webkit-flex-grow: 1;-ms-flex-grow: 1;flex-grow: 1;}
.growhide {overflow: hidden; -webkit-flex-grow: 1;-ms-flex-grow: 1;flex-grow: 1;}
.no-grow {-webkit-flex-grow: 0;-ms-flex-grow: 0;flex-grow: 0;}
.self-stretch {-webkit-align-self: stretch; -ms-align-self: stretch;align-self: stretch;}
.self-end {-webkit-align-self: flex-end;-ms-align-self: flex-end; align-self: flex-end;}
.flex-start {-webkit-justify-content: flex-start;-ms-justify-content: flex-start;justify-content: flex-start;}
.flex-end {-webkit-justify-content: flex-end;-ms-justify-content: flex-end;justify-content: flex-end;}
.flex-bot {-webkit-align-items: flex-end;-ms-align-items: flex-end; align-items: flex-end;}
.flex-top {-webkit-align-items: flex-start;-ms-align-items: flex-start; align-items: flex-start;}
.no-shrink,.noshrink-childs > * {-webkit-flex-shrink: 0; -ms-flex-shrink: 0; flex-shrink: 0;}
.flex > div.no-shrink {-ms-flex-shrink: 0; flex-shrink: 0;}
.flex.noshrink-childs > div {-ms-flex-shrink: 0; flex-shrink: 0;}
.grow-childs > * {-webkit-flex-grow: 1;-ms-flex-grow: 1;flex-grow: 1;}
.v-center {-webkit-align-items: center;-ms-align-items: center;align-items: center;}
.h-center {-webkit-justify-content: center;-ms-justify-content: center; justify-content: center;}
.flex-full > * {width: 100%;}
.inline-block {display: inline-block;}
.inline-childs > * {vertical-align: middle; display: inline-block;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}
.text-uppercase {text-transform: uppercase;}
.hidden {display: none;}
.invisible {visibility: hidden; opacity: 0;}
.visible {visibility: visible; opacity: 1;}
.text-normal {text-transform: none;}
.normal {font-weight: normal;}
.absolute {position: absolute; width: 100%;}
.img > img{display: block;}
.mgbot {margin-bottom: 10px;}
.mgright {margin-right: 10px;}
.float-left {float: left;}
.material-icons {font-size: 1em;}
.fixed-table {table-layout: fixed;}
.big {font-size: 1.2em;}
